import React from "react";
import {
  colorCarruselHigiene,
  colorHigiene,
  srcLogoHigiene,
} from "../components/UnidadesNegocioHome";
import { UnidadesNegocio } from "../components/UnidadesNegocio";

const logos = "clientes_higiene.png";

const Higiene = () => {
  return (
    <UnidadesNegocio
      colorCarrusel={colorCarruselHigiene}
      colorUnidad={colorHigiene}
      logos={logos}
      midTitle={"HIGIENE INSTITUCIONAL"}
      srcLogo={srcLogoHigiene}
      title={"HIGIENE INSTITUCIONAL"}
      unidadNegocio={"higiene"}
    />
  );
};
export default Higiene;

import React from "react";
import styled from "styled-components";
import { Carousel, Col, Container, Image, Row } from "react-bootstrap";
import { APIURL } from "../services/productos.services";
import { useCheckMobileScreen } from "./util.js";
const CardCarousel = styled.div`
  background: white;
  border-radius: 5em 0;
  width: 65%;
  overflow: hidden;
`;

export const Carrusel = ({ data, backgroundColor, textColor }) => {
  const isMobile = useCheckMobileScreen();
  return (
    <Carousel fade variant="dark" style={{ backgroundColor }} interval={3000}>
      {data?.map((item, i) => {
        const url = `${APIURL}/${item.url_imagen}`.replace("\\", "/");
        return (
          <Carousel.Item
            key={JSON.stringify(item)}
            style={{ padding: "3rem 0" }}
          >
            <div style={{ backgroundColor }}>
              <Container>
                <Row>
                  <Col xs={12} lg={4}>
                    <Container className="d-block w-100">
                      <Row
                        style={{
                          alignItems: "center",
                          padding: "0 0 1rem 2rem",
                        }}
                      >
                        <Col lg={12} xs={12} style={{ color: `${textColor}` }}>
                          El más vendido
                        </Col>
                        <Col lg={12} xs={12}>
                          <h4>{item.nombre}</h4>
                        </Col>
                        <Col lg={12} className="d-none d-sm-block">
                          <div
                            style={{ maxHeight: "15rem", overflow: "hidden" }}
                          >
                            {item.descripcion}
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col style={{ textAlign: "-webkit-center" }}>
                    <CardCarousel>
                      <Image
                        alt={item.url_imagen}
                        src={url}
                        style={{
                          maxHeight: isMobile ? "12rem" : "20rem",
                        }}
                      />
                    </CardCarousel>
                  </Col>
                </Row>
              </Container>
            </div>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

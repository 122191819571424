import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Contact from "../components/Contact";
import { Espacio } from "../components/util";
import Clients from "../components/Clients";
import Dialog from "../components/Dialog";
import ItemDetail from "../components/ItemDetail";
import Articles from "../components/Articles";
import {
  imgApiCarrusel,
  imgApiCategorias,
} from "../services/productos.services";
import { Container, Image } from "react-bootstrap";
import { Carrusel } from "../components/Carousel";

export const UnidadesNegocio = ({
  logos,
  unidadNegocio,
  srcLogo,
  midTitle,
  title,
  colorUnidad,
  colorCarrusel,
}) => {
  const [imagenesCarrusel, setImagenesCarrusel] = useState();
  const [categorias, setCategorias] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [errorDb, setErrorDb] = useState(true);
  const [selectedItem, setSelectedItem] = useState();
  const onClick = (item) => {
    setShowModal(!showModal);
    setSelectedItem(item);
  };

  const onClose = () => {
    setShowModal(!showModal);
    setSelectedItem();
  };

  useEffect(() => {
    imgApiCarrusel(unidadNegocio)
      .then((res) => {
        res?.data && setImagenesCarrusel(res.data);
        setErrorDb(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorDb(true);
      });
    imgApiCategorias(unidadNegocio)
      .then((res) => {
        res?.data && setCategorias(res.data);
        setErrorDb(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorDb(true);
      });
  }, [unidadNegocio]);
  return (
    <>
      <Navbar
        logoNavbar={<Image style={{ width: "15rem" }} rounded src={srcLogo} />}
        midTitle={midTitle}
        title={title}
        color={colorUnidad}
      />
      {!errorDb && (
        <>
          <Espacio height="2em" />
          <Carrusel
            data={imagenesCarrusel}
            backgroundColor={colorCarrusel}
            textColor={colorUnidad}
          />
          <Espacio height="2em" />
        </>
      )}
      <Container>
        {!errorDb && (
          <>
            <h4>Productos</h4>
            <Articles articles={categorias} onClick={onClick} />
            <Espacio height="2em" />
          </>
        )}
        <Clients logos={logos} height={"12rem"} />
        <Contact
          textolor="rgb(255, 255, 255)"
          color={colorUnidad}
          title="Solicita tu presupuesto aquí"
          idnegocio="1"
        />
      </Container>
      <Dialog
        show={showModal}
        body={<ItemDetail item={selectedItem} empresaId={unidadNegocio} />}
        title={title}
        // subtitle={"PROTOCOLOS DE PREVENCIÓN COVID - 19"}
        onClose={onClose}
        color={colorUnidad}
      />
    </>
  );
};

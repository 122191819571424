import React, { useState } from "react";

const Treeview = ({ data, onChangeChecked }) => {
  // console.log({ data, onChangeChecked });
  return (
    <div>
      <ul style={{ listStyle: "none", padding: "0px", width: "300px" }}>
        {data.map((tree, i) => (
          <TreeNode key={i} node={tree} onChangeChecked={onChangeChecked} />
        ))}
      </ul>
    </div>
  );
};

const TreeNode = ({ node, onChangeChecked }) => {
  const [childVisibility, setChildVisibility] = useState(false);
  const hasChild = node.children ? true : false;

  return (
    <li>
      <div>
        {hasChild && <div></div>}
        <div>
          {hasChild ? (
            childVisibility ? (
              <i
                style={{ marginRight: "5px", cursor: "pointer" }}
                onClick={() => setChildVisibility(!childVisibility)}
              >
                -
              </i>
            ) : (
              <i
                style={{ marginRight: "5px", cursor: "pointer" }}
                onClick={() => setChildVisibility(!childVisibility)}
              >
                +
              </i>
            )
          ) : null}
          <input
            type="checkbox"
            checked={node.checked}
            onChange={() => onChangeChecked(node)}
            style={{ marginRight: "5px" }}
          />
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setChildVisibility(!childVisibility)}
          >
            {node.text}
          </span>
        </div>
      </div>

      {hasChild && childVisibility && (
        <div>
          <ul>
            <Treeview data={node.children} onChangeChecked={onChangeChecked} />
          </ul>
        </div>
      )}
    </li>
  );
};
export default Treeview;

import React from "react";
import {
  colorCarruselSanitizantes,
  colorSanitizantes,
  srcLogoSanitizantes,
} from "../components/UnidadesNegocioHome";
import { UnidadesNegocio } from "../components/UnidadesNegocio";

const logos = "clientes_sanitizantes.png";

const Sanitizantes = () => {
  return (
    <UnidadesNegocio
      colorCarrusel={colorCarruselSanitizantes}
      colorUnidad={colorSanitizantes}
      logos={logos}
      midTitle={"SANITIZANTES"}
      srcLogo={srcLogoSanitizantes}
      title={"SANITIZANTES"}
      unidadNegocio={"sanitizantes"}
    />
  );
};
export default Sanitizantes;

import { CenterDiv } from "./util";

export const NosotrosHome = () => (
  <CenterDiv>
    <h2 style={{ paddingTop: "1.5rem" }}>NOSOTROS</h2>
    <p style={{ textAlign: "center", paddingBottom: "1rem", width: "70%" }}>
      Somos un empresa Argentina destinada a la elaboración, provisión y
      distribución de productos y servicios de salud. Abarcamos desde la
      producción de productos personalizados, siguiendo las mejores practicas de
      higiene y seguridad, hasta la logística de entrega siempre priorizando
      cumplir con los plazos pactados
    </p>
  </CenterDiv>
);

import facebook from "../assets/images/facebook.svg";
import instagram from "../assets/images/instagram.svg";
import linkedin from "../assets/images/linkedin.svg";
import whatsapp from "../assets/images/whatsapp.svg";
import styled from "styled-components";

const DivA = styled.div`
  text-align: center;
  img {
    margin: 0.6rem 0.9rem;
  }
`;
const ExternalLinks = () => {
  const sizeImg = "1.7rem";
  return (
    <DivA>
      <a
        href="https://www.facebook.com/provexsrl/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt="Facebook"
          src={facebook}
          style={{ height: sizeImg, width: sizeImg }}
        />
      </a>
      <a
        href="https://api.whatsapp.com/send?phone=5491158743500"
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt="Whatsapp"
          src={whatsapp}
          style={{ height: sizeImg, width: sizeImg }}
        />
      </a>
      <a
        href="https://www.instagram.com/provexsrl/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt="Instagram"
          src={instagram}
          style={{ height: sizeImg, width: sizeImg }}
        />
      </a>
      <a
        href="https://ar.linkedin.com/company/provex-srl"
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt="Linkedin"
          src={linkedin}
          style={{ height: sizeImg, width: sizeImg }}
        />
      </a>
    </DivA>
  );
};
export default ExternalLinks;

export const rgb2Hex = (r, g, b) =>
  "#" + [r, g, b].map((x) => x.toString(16).padStart(2, "0")).join("");

export const rgbString2Hex = (rgb) =>
  rgb.includes("#")
    ? rgb
    : "#" +
      rgb
        .match(/\d+/g)
        .map((x) => (+(x + "")).toString(16).padStart(2, "0"))
        .join("");

import React from "react";
import {
  colorCarruselOrtopedia,
  colorOrtopedia,
  srcLogoOrtopedia,
} from "../components/UnidadesNegocioHome";
import { UnidadesNegocio } from "../components/UnidadesNegocio";

const logos = "clientes_ortopedia.png";

const Ortopedia = () => {
  return (
    <UnidadesNegocio
      colorCarrusel={colorCarruselOrtopedia}
      colorUnidad={colorOrtopedia}
      logos={logos}
      midTitle={
        <div style={{ fontSize: "initial" }}>
          ORTOPEDIA, DISCAPACIDAD DE ALTA COMPLEJIDAD Y REHABILITACIÓN.
        </div>
      }
      srcLogo={srcLogoOrtopedia}
      title={"ORTOPEDIA"}
      unidadNegocio={"ortopedia"}
    />
  );
};

export default Ortopedia;

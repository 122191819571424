import axios from "axios";

export const APIURL = process.env.REACT_APP_API_URL;
// console.log({ URL });
export const imgApiCarrusel = async (empresa) => {
  try {
    const carrusel = await axios.get(`${APIURL}/productos/carrusel/${empresa}`);

    return carrusel;
  } catch (error) {
    // console.log("error", error);
  }
};

export const imgApiCategorias = async (empresa) => {
  try {
    const carrusel = await axios.get(
      `${APIURL}/productos/categorias/${empresa}`
    );

    return carrusel;
  } catch (error) {
    // console.log("error", error);
  }
};

export const postContacto = async (contacto) => {
  try {
    const Contacto = await axios.post(`${APIURL}/productos/contacto`, {
      contacto,
    });

    return Contacto;
  } catch (error) {
    // console.log("error", error);
  }
};

import { Button, Carousel, Col, Container, Image, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import {
  colorHigiene,
  colorOrtopedia,
  colorSanitizantes,
  srcAcordionHomeHigienemovil,
  srcAcordionHomeOrtopedia,
  srcAcordionHomePureti,
  srcAcordionHomeSanitizantesMovil,
  srcLogoDarsoftSolInfoFondoMovil,
  srcLogoDarsoftSolInfoLogoMovil,
  srcLogoHigiene,
  srcLogoOrtopedia,
  srcLogoSanitizantes,
  srcLogoSinFondoPureti,
  urlPureti,
} from "./UnidadesNegocioHome";

const BtnIngresarSitio = ({ color }) => (
  <Button
    style={{
      width: "80%",
      margin: "1rem 0",
      backgroundColor: color,
      borderColor: color,
      textAlign: "center",
      alignItems: "center",
    }}
  >
    Ingresar al sitio
  </Button>
);

const CenterCol = ({ children }) => (
  <Col
    style={{
      textAlign: "center",
      alignItems: "center",
      alignSelf: "center",
    }}
  >
    {children}
  </Col>
);

const Carrusel = styled(Carousel)`
  height: 14rem;
  .carousel-inner {
    height: 100%;
  }
`;

const CarruselItem = ({ link, srcLogo, btnColor }) => (
  <NavLink
    to={link}
    style={{ height: "100%" }}
    target={srcLogo ? "_blank" : ""}
  >
    <Container style={{ height: "100%" }}>
      <Row style={{ height: "100%" }}>
        <CenterCol>
          <Image
            rounded
            src={srcLogo}
            style={{ maxWidth: "60%", maxHeight: "6rem", height: "auto" }}
          />
          <BtnIngresarSitio color={btnColor} />
        </CenterCol>
      </Row>
    </Container>
  </NavLink>
);

export const CarruselHome = () => (
  <Carrusel
    fade
    variant="dark"
    className="d-block d-sm-none"
    style={{ height: "80vh" }}
  >
    <Carousel.Item
      style={{
        height: "100%",
        backgroundImage: `url("${srcAcordionHomeOrtopedia}")`,
      }}
    >
      <CarruselItem
        link={"/ortopedia"}
        srcLogo={srcLogoOrtopedia}
        btnColor={colorOrtopedia}
      />
    </Carousel.Item>
    <Carousel.Item
      style={{
        height: "100%",
        backgroundImage: `url("${srcAcordionHomeSanitizantesMovil}")`,
      }}
    >
      <CarruselItem
        link={"/sanitizantes"}
        srcLogo={srcLogoSanitizantes}
        btnColor={colorSanitizantes}
      />
    </Carousel.Item>
    <Carousel.Item
      style={{
        height: "100%",
        backgroundImage: `url("${srcAcordionHomeHigienemovil}")`,
      }}
    >
      <CarruselItem
        link={"/higiene"}
        srcLogo={srcLogoHigiene}
        btnColor={colorHigiene}
      />
    </Carousel.Item>
    <Carousel.Item
      style={{
        height: "100%",
        backgroundImage: `url("${srcLogoDarsoftSolInfoFondoMovil}")`,
      }}
    >
      <CarruselItem
        link={"/darsoft"}
        srcLogo={srcLogoDarsoftSolInfoLogoMovil}
        btnColor={"grey"}
      />
    </Carousel.Item>
    <Carousel.Item
      style={{
        height: "100%",
        backgroundImage: `url("${srcAcordionHomePureti}")`,
      }}
    >
      <CarruselItem
        link={{ pathname: urlPureti }}
        srcLogo={srcLogoSinFondoPureti}
        btnColor={"blue"}
      />
    </Carousel.Item>
  </Carrusel>
);

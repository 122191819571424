const SearchBar = ({ onChange }) => {
  return (
    <input
      style={{
        borderRadius: "5px",
        border: "1px solid",
        borderColor: "rgb(223, 223, 223)",
        height: "37px",
        width: "300px",
      }}
      placeholder={"Código o descripción del producto"}
      onChange={(event) => onChange(event.target.value)}
    />
  );
};

export default SearchBar;

import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import {
  LogoCentrado,
  // Grid,
  // GridArea,
  imgLogoProvex,
} from "../components/util";
import { postContacto } from "../services/productos.services";
import { rgbString2Hex } from "../utils/funciones";
import ExternalLinks from "./ExternalLinks";

const CuadradoContacto = styled.div`
  min-height: 22em;
  max-width: 30rem;
  background-color: ${(p) =>
    p.color ? rgbString2Hex(p.color) + "66" : "#e7eaed"};
  border-radius: 0 3em;
  padding: 2em;
  margin: 2em 0;
`;

const Contact = ({ title, color, textColor, idnegocio = null }) => {
  const initContacto = {
    nombre: "",
    empresa: "",
    email: "",
    telefono: "",
    consulta: "",
    idnegocio,
  };
  const [contacto, setContacto] = useState(initContacto);
  // console.log({ colorRgb: color, colorHexa: rgbString2Hex(color) });
  const enviarContacto = (e) => {
    e.preventDefault();
    // console.log({ contacto });
    postContacto(contacto).then((res) => {
      if (res.status === 200) {
        alert("Se envio su contacto");
        setContacto(initContacto);
      } else alert("Ocurrio un error");

      // console.log({ res });
    });
  };
  // console.log({ color });
  return (
    <Container>
      <Row>
        <Col lg={3} sm={3} style={{ alignSelf: "center", textAlign: "center" }}>
          <Container style={{ paddingTop: "1rem" }}>
            <h2>CONTACTO</h2>
            <p style={{ margin: "0" }}>
              info@provex.com.ar<br></br>+54911-3708-1302<br></br>
              +54911-5874-3500
            </p>
          </Container>
        </Col>
        <Col
          xs={12}
          lg={6}
          sm={6}
          style={{ padding: "0", textAlign: "-webkit-center" }}
        >
          <CuadradoContacto color={color}>
            <form
              style={{
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div className="form-row">
                <div
                  className="form-group col-md-6"
                  style={{ textAlign: "left" }}
                >
                  <label htmlFor="inputNombre" style={{ marginBottom: "0px" }}>
                    Nombre
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputNombre"
                    style={{ borderRadius: "10px" }}
                    value={contacto.nombre}
                    onChange={(e) =>
                      setContacto({ ...contacto, nombre: e.target.value })
                    }
                  />
                </div>
                <div
                  className="form-group col-md-6"
                  style={{ textAlign: "left" }}
                >
                  <label htmlFor="inputEmpresa" style={{ marginBottom: "0px" }}>
                    Institución/Empresa
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputEmpresa"
                    style={{ borderRadius: "10px" }}
                    value={contacto.empresa}
                    onChange={(e) =>
                      setContacto({ ...contacto, empresa: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="form-row">
                <div
                  className="form-group col-md-6"
                  style={{ textAlign: "left" }}
                >
                  <label htmlFor="inputEmail4" style={{ marginBottom: "0px" }}>
                    E-mail
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="inputEmail4"
                    style={{ borderRadius: "10px" }}
                    value={contacto.email}
                    onChange={(e) =>
                      setContacto({ ...contacto, email: e.target.value })
                    }
                  />
                </div>
                <div
                  className="form-group col-md-6"
                  style={{ textAlign: "left" }}
                >
                  <label
                    htmlFor="inputTelefono"
                    style={{ marginBottom: "0px" }}
                  >
                    Teléfono
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputTelefono"
                    style={{ borderRadius: "10px" }}
                    value={contacto.telefono}
                    onChange={(e) =>
                      setContacto({ ...contacto, telefono: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="form-group" style={{ textAlign: "left" }}>
                <label htmlFor="inputMotivo" style={{ marginBottom: "0px" }}>
                  Ingrese el motivo de su consulta
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  id="inputMotivo"
                  style={{ borderRadius: "10px" }}
                  value={contacto.consulta}
                  onChange={(e) =>
                    setContacto({ ...contacto, consulta: e.target.value })
                  }
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={enviarContacto}
                style={{
                  borderRadius: "1rem",
                  backgroundColor: `${color + "99"}`,
                  borderColor: `${color}`,
                  color: "black",
                  width: "40%",
                }}
              >
                ENVIAR
              </button>
            </form>
          </CuadradoContacto>
        </Col>
        <Col lg={3} sm={3} style={{ alignSelf: "center", textAlign: "center" }}>
          <LogoCentrado center>
            <img
              src={"/" + imgLogoProvex}
              alt="provex"
              style={{
                textAlign: "center",
                maxWidth: "-webkit-fill-available",
              }}
            />
            <ExternalLinks />
          </LogoCentrado>
        </Col>
      </Row>
    </Container>
  );
};
export default Contact;

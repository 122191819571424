/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Image } from "react-bootstrap";
import {
  srcAcordionHomeHigiene,
  srcAcordionHomeOrtopedia,
  srcAcordionHomePureti,
  srcAcordionHomeSanitizantes,
  srcHomeDarsoft,
  srcLogoHigiene,
  srcLogoOrtopedia,
  srcLogoSanitizantes,
  urlPureti,
} from "./UnidadesNegocioHome";

const AcordionContainer = styled.div`
  margin: 0 auto;
  max-width: 100% !important;
  padding: 0px !important;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;
export const GalleryWrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 70vh;
`;
export const Item = styled.div`
  flex: 1;
  height: 100%;
  background-image: url(${(p) => p.img});
  background-position: center;
  background-size: cover;
  background-repeat: none;
  transition: flex 0.8s ease;
  margin: 0 0.25rem;
  color: white;
  font-family: Roboto, sans-serif;
  font-size: 31px;
  font-weight: 400;
  font-style: normal;
  /* font-variant: small-caps; */
  /* text-transform: capitalize; */

  &:hover {
    flex: 3;
    .listaAcordeon {
      /* display: block;
      visibility: visible;
      opacity: 1; */
    }
  }
`;

const BottomPanel = styled.div`
  /* font-family: sans-serif; */
  /* font-weight: normal; */
  font-size: 25px;
  /* letter-spacing: 2px; */
  /* text-align: center; */
  /* height:100%; */
  width: 100%;
  display: flex;
  justify-content: center;
  /* padding: 100% 0 0 0; */
  .listaAcordeon {
    color: white;

    /* visibility: hidden;
    opacity: 0;
    display: none;
    transition: visibility 0s, opacity 0.5s linear; */
  }
  cursor: pointer;
  a {
    color: white;
  }
`;

const tamañoLogos = "9rem";
const BlurBackground = styled.div`
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  width: 100%;
`;

const FondoNombre = ({ children }) => (
  <BlurBackground>
    <div style={{}}>{children}</div>
  </BlurBackground>
);

// const FondoNombre = ({ children }) => (
//   <Alert
//     style={{
//       background:
//         "radial-gradient(circle, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.6) 100%)",
//       border: "none",
//       padding: "0.3rem",
//     }}
//   >
//     {children}
//   </Alert>
// );

const DownPosition = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
`;

const LogoCentrado = ({ src, width = tamañoLogos }) => (
  <div style={{ textAlign: "center", padding: "1.5rem 0" }}>
    <Image style={{ width }} rounded src={src} />
  </div>
);
const COLORTEXTOACORDEON = "#111111";
export const AcordionHome = () => (
  <AcordionContainer>
    <GalleryWrap>
      <Item img={srcAcordionHomeOrtopedia}>
        <NavLink to="/ortopedia">
          <DownPosition
            style={{ color: COLORTEXTOACORDEON, textDecoration: "none" }}
          >
            <BottomPanel>
              <FondoNombre style={{ width: "15rem" }}>
                <div>
                  <LogoCentrado src={srcLogoOrtopedia} />
                  <ul
                    className="listaAcordeon"
                    style={{ fontSize: "small", paddingBottom: "0.5rem" }}
                  >
                    <li> Movilidad y postura </li>
                    <li> Equipamiento para discapacidad</li>
                    <li> Ortesis y protesis</li>
                  </ul>
                </div>
              </FondoNombre>
            </BottomPanel>
          </DownPosition>
        </NavLink>
      </Item>
      <Item img={srcAcordionHomeSanitizantes}>
        <NavLink to="/sanitizantes">
          <DownPosition
            style={{ color: COLORTEXTOACORDEON, textDecoration: "none" }}
          >
            <BottomPanel>
              <FondoNombre>
                <div>
                  <LogoCentrado width="8rem" src={srcLogoSanitizantes} />
                  <ul
                    className="listaAcordeon"
                    style={{ fontSize: "small", paddingBottom: "0.5rem" }}
                  >
                    <li>Cosmética sanitaria </li>
                    <li>
                      Fabricación de productos antibacteriales y antivirales
                    </li>
                    <li>
                      <Image
                        style={{ width: "2rem" }}
                        rounded
                        src="/logoAnmat.png"
                      />
                      <Image
                        style={{ width: "2rem" }}
                        rounded
                        src={srcLogoHigiene}
                      />
                    </li>
                  </ul>
                </div>
              </FondoNombre>
            </BottomPanel>
          </DownPosition>
        </NavLink>
      </Item>
      <Item img={srcAcordionHomeHigiene}>
        <NavLink to="/higiene">
          <DownPosition
            style={{ color: COLORTEXTOACORDEON, textDecoration: "none" }}
          >
            <BottomPanel>
              <FondoNombre>
                <div>
                  <LogoCentrado width="8rem" src={srcLogoHigiene} />
                  <ul
                    className="listaAcordeon"
                    style={{
                      fontSize: "small",
                      paddingBottom: "0.5rem",
                      color: "white",
                    }}
                  >
                    <li>Dispensers e insumos </li>
                    <li>Fabricación de domisanitarios </li>
                    {/* <li>Distribución de 1ras marcas de higiene</li> */}
                    {/* <li>Aplicación de nanotecnología descontaminante </li> */}
                    <li>
                      <Image
                        style={{ width: "2rem" }}
                        rounded
                        src="/logoAnmat.png"
                      />
                      <Image
                        style={{ width: "2rem" }}
                        rounded
                        src="logosUnidades/LOGO_REKOP.png"
                      />
                      <Image
                        style={{ width: "2rem" }}
                        rounded
                        src={srcLogoSanitizantes}
                      />
                    </li>
                  </ul>
                </div>
              </FondoNombre>
            </BottomPanel>
          </DownPosition>
        </NavLink>
      </Item>
      <Item img={srcHomeDarsoft}>
        <NavLink to="/darsoft">
          <DownPosition
            style={{ color: COLORTEXTOACORDEON, textDecoration: "none" }}
          >
            <BottomPanel>
              <FondoNombre>
                <div style={{ textAlign: "center", padding: "1rem 0" }}>
                  Darsoft
                </div>
                <small>
                  {/* <LogoCentrado src={srcLogoSanitizantes} /> */}
                  <ul
                    className="listaAcordeon"
                    style={{ fontSize: "small", paddingBottom: "0.5rem" }}
                  >
                    <li>Informatica en salud </li>
                    <li> Sistemas internos operacionales</li>
                    <li> gestión de procesos asistenciales</li>
                  </ul>
                </small>
              </FondoNombre>
            </BottomPanel>
          </DownPosition>
        </NavLink>
      </Item>
      <Item img={srcAcordionHomePureti} pureti>
        <a
          // to={urlPureti}
          onClick={(_) => {
            window.location.href = urlPureti;
          }}
          // target="_blank"
          style={{
            color: COLORTEXTOACORDEON,
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          <div style={{ height: "100%" }}></div>
        </a>
      </Item>
    </GalleryWrap>
  </AcordionContainer>
);

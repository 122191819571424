import Card from "react-bootstrap/Card";
import SearchBar from "./SearchBar";
import Pages from "../components/Pages";
import { Espacio } from "../components/util";
import { useCallback, useEffect, useState } from "react";
import Treeview from "./Treeview";
import { APIURL } from "../services/productos.services";

const Articles = ({ articles, onClick }) => {
  const [shownArticles, setShownArticles] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [itemsPerPage] = useState(12);
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [categories, setCategories] = useState([]);
  // console.log({ articles });
  useEffect(() => {
    const updateArticlesToShow = () => {
      const articlesToShow = [...articles];
      setShownArticles(articlesToShow);
    };
    updateArticlesToShow();
    buildCategoriesTree(articles);
  }, [articles]);

  const paginateItems = useCallback(() => {
    const indexOfLastItem = selectedPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const paginatedItems = shownArticles.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    setPaginatedItems(paginatedItems);
  }, [shownArticles, selectedPage, itemsPerPage]);

  useEffect(() => {
    paginateItems();
  }, [paginateItems]);

  useEffect(() => {
    if (
      categories.some((category) =>
        category.children.some((child) => child.checked)
      )
    ) {
      const selectedCategories = categories.flatMap(({ id, children }) =>
        children.filter(({ checked }) => checked).map(({ id }) => id)
      );
      setShownArticles(
        articles.filter((article) =>
          selectedCategories.includes(article.id_categoria)
        )
      );
    } else setShownArticles([]);
  }, [categories, articles]);
  // console.log({ articles });
  const onSearchChange = (value) => {
    if (value !== undefined && value !== "")
      setShownArticles(
        articles.filter(
          (article) =>
            article.codigo.includes(value.toUpperCase()) ||
            article.nombre.includes(value.toUpperCase())
        )
      );
    else setShownArticles(articles);

    if (selectedPage !== 1) setSelectedPage(1);

    paginateItems();
  };

  const onClickPagination = (newPage) => {
    setSelectedPage(newPage);
  };

  const buildCategoriesTree = (articles) => {
    let categoriasTree = [];
    const uniqueCategoriasPadre = [
      ...new Set(
        articles
          .filter((article) => article.id_categoria_padre !== null)
          .map((article) => article.id_categoria_padre)
      ),
    ];
    // console.log({ uniqueCategoriasPadre });
    for (let index = 0; index < uniqueCategoriasPadre.length; index++) {
      const categoriaTree = {
        text: articles.find(
          (articulo) =>
            articulo.id_categoria_padre === uniqueCategoriasPadre[index]
        ).nombre_categoria_padre,
        id: uniqueCategoriasPadre[index],
        checked: true,
        children: [],
      };

      const uniqueCategorias = [
        ...new Set(
          articles
            .filter(
              (article) =>
                article.id_categoria_padre === uniqueCategoriasPadre[index]
            )
            .map((article) => article.id_categoria)
        ),
      ];

      const newCategoria = uniqueCategorias.map((article) => ({
        id: article,
        text: articles.find((articulo) => articulo.id_categoria === article)
          .nombre_categoria,
        checked: true,
      }));
      categoriasTree.push({ ...categoriaTree, children: newCategoria });
    }
    setCategories(categoriasTree);
  };

  const onFilteredCategoriesChange = (category) => {
    let temporaryCategories = [...categories];
    if (category.children !== undefined) {
      const categoryIndex = temporaryCategories.findIndex(
        (cat) => cat.id === category.id
      );
      const checked = !temporaryCategories[categoryIndex].checked;
      temporaryCategories[categoryIndex] = {
        ...temporaryCategories[categoryIndex],
        checked: checked,
      };

      temporaryCategories[categoryIndex].children = temporaryCategories[
        categoryIndex
      ].children.map((child) => ({
        ...child,
        checked: checked,
      }));
    } else {
      const categoryIndex = temporaryCategories.findIndex((cat) =>
        cat.children.some((child) => child.id === category.id)
      );
      const childrenIndex = temporaryCategories[
        categoryIndex
      ].children.findIndex((child) => child.id === category.id);
      const checked =
        !temporaryCategories[categoryIndex].children[childrenIndex].checked;
      temporaryCategories[categoryIndex].children[childrenIndex] = {
        ...temporaryCategories[categoryIndex].children[childrenIndex],
        checked: checked,
      };
    }
    setCategories(temporaryCategories);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingRight: "22px",
          }}
        >
          <h5>Categorías</h5>
          <SearchBar onChange={onSearchChange} />
        </div>
        <div className="row">
          <div className="col-xs-6 col-md-4">
            <Treeview
              data={categories}
              onChangeChecked={onFilteredCategoriesChange}
            />
          </div>
          <div className="col-xs-12 col-md-8">
            <div className="row px-3">
              {paginatedItems?.map((article, index) => (
                <div key={index} className="col p-1">
                  <Card
                    style={{
                      width: "10rem",
                      height: "15rem",
                      cursor: "pointer",
                    }}
                    onClick={() => onClick(article)}
                  >
                    <Card.Body
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Card.Img
                        variant="top"
                        src={`${APIURL}/${article.url_imagen}`}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = "producto_no_disponible3.png";
                        }}
                      />
                      <Card.Title
                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                      >
                        {article.nombre}
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Espacio height="2em" />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "22px",
        }}
      >
        <Pages
          totalItems={shownArticles?.length ?? 1}
          itemsPerPage={12}
          active={selectedPage}
          onClick={onClickPagination}
        />
      </div>
    </div>
  );
};

export default Articles;

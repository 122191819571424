import React from "react";
import styled from "styled-components";
import Contact from "../components/Contact";
import { Container } from "react-bootstrap";
import {
  LogoCentrado,
  Espacio,
  Footer,
  imgLogoProvex,
} from "../components/util";
import Clients from "../components/Clients";
import { NosotrosHome } from "../components/NosotrosHome.jsx";
import { VirtudesHome } from "../components/VirtudesHome.jsx";
import { UnidadesNegocioHome } from "../components/UnidadesNegocioHome.jsx";

const BodyHome = styled.div`
  background-color: #f0f9ea;
  /* background: #aecfa0; 
  background: -webkit-linear-gradient(
    to top,
    #f0f9ea,
    #aecfa0
  ); 
  background: linear-gradient(
    to top,
    #f0f9ea,
    #aecfa0
  );  */
`;

const Home = () => (
  <BodyHome>
    <LogoCentrado style={{ textAlign: "center", padding: "1rem 0" }}>
      <img src={imgLogoProvex} alt="provex" />
    </LogoCentrado>
    <UnidadesNegocioHome />
    <Container style={{ textAlign: "center", paddingTop: "1rem" }}>
      <NosotrosHome />
      <VirtudesHome />
      <Clients logos={"Logos-Client.png"} height={"15rem"} />
    </Container>
    <Espacio height="1em" />
    <Footer>
      <Contact color="rgb(204, 235, 182)" textColor="rgb(69, 126, 92)" />
    </Footer>
  </BodyHome>
);

export default Home;

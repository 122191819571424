import React from "react";
import { Espacio } from "../components/util";
import styled from "styled-components";

const TitulosHome = styled.h2`
  text-transform: uppercase;
`;

const Clients = ({ logos, height = "15rem" }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Espacio height="1em" />
      <TitulosHome style={{ paddingBottom: "1rem", paddingTop: "2rem" }}>
        Nuestros principales clientes
      </TitulosHome>
      <div
        style={{
          overflowX: "auto",
          whiteSpace: "nowrap",
          paddingBottom: "3rem",
        }}
      >
        <img alt={logos} src={logos} style={{ height }} />
      </div>

      <Espacio height="1em" />
    </div>
  );
};

export default Clients;

// import React, { useState } from "react";
// import styled from "styled-components";
import Navbar from "../components/Navbar";
import { imgLogoProvex, LogoCentrado } from "../components/util";
import styled from "styled-components";
import { Image } from "react-bootstrap";
import Contact from "../components/Contact";
import { srcLogoDarsoft } from "../components/UnidadesNegocioHome";

const PaginaDarsoft = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
 `;
// const ImagenRotada = styled.div`
//   .rotate {
//     animation: rotation 4s;
//   }
//   .linear {
//     animation-timing-function: linear;
//   }
//   .infinite {
//     animation-iteration-count: infinite;
//   }
//   @keyframes rotation {
//     from {
//       transform: rotate(0deg);
//     }
//     to {
//       transform: rotate(359deg);
//     }
//   }
// `;
const Darsoft = () => {
  // const [javiRota, setJaviRota] = useState(false);
  return (
    <>
      <Navbar>
        <LogoCentrado style={{ transform: "scale(0.7)" }}>
          <img src={imgLogoProvex} alt="provex" />
        </LogoCentrado>

        <a className="navbar-brand" href="/">
          <h2>Darsoft</h2>
        </a>
        <div>
          <button type="button" className="btn btn-primary m-3">
            Servicios
            <br /> personalizados
          </button>
          <button type="button" className="btn btn-primary">
            Convenios
            <br /> especiales
          </button>
        </div>
      </Navbar>
      <PaginaDarsoft>
        <Image src={srcLogoDarsoft} />
        <div
          style={{
            backgroundColor: "#151515cc",
            padding: "4rem 4rem",
            color: "whiteSmoke",
          }}
        >
          <h3>Misiones y funciones</h3>
          Fabricar, industrializar, ensamblar, importar, exportar, distribuir,
          comisionar, y comercializar bajo cualquier modalidad, sistemas y
          productos de computación, electrónicos, programas y equipos de
          informática; pudiendo brindar servicios vinculados a la informática y
          tecnología, incluyendo alquiler y consignación de hardware y software,
          servicio técnico, de software y consultoría informática, auditoria,
          investigación, desarrollo, asesoramiento, capacitación e
          implementación, extensión y mantenimiento de aplicaciones, gestión y
          administración de datos públicos y privados, análisis estadísticos,
          seguridad informática, documentación digital y electrónica y
          telemedicina; pudiendo ejercer representaciones, consignaciones,
          comisiones, gestoría de negocios y mandatos, participar en
          licitaciones públicas o privadas, administrar y financiar - fuera de
          la ley de entidades financieras - operaciones de empresas vinculadas a
          dicho rubro. Las actividades que así lo requieran serán llevadas a
          cabo por profesionales con título habilitante en la materia.
        </div>
        <Contact
          style={{ backgroundColor: "red" }}
          textcolor="rgb(255, 255, 255)"
          color="rgb(21, 21, 21)"
          title="Solicita tu presupuesto aquí"
        />
      </PaginaDarsoft>
    </>
  );
};
export default Darsoft;

  //  <ImagenRotada>
  //     <img
  //       onClick={() => setJaviRota(!javiRota)}
  //       className={javiRota && "rotate linear infinite"}
  //       src="darjavi.png"
  //       alt="asfasda"
  //     />
  //   </ImagenRotada>

import React from "react";
import styled from "styled-components";

export const StyledDivCentrado = styled.div`
  text-align: center;
  align-items: center;
  text-align: -webkit-center;
`;

export const CenterDiv = (props) => (
  <StyledDivCentrado>{props.children}</StyledDivCentrado>
);

export const LogoCentrado = styled.div`
  text-align: ${(p) => (p.center ? "center" : "left")};
  padding: 8px;
  img {
    width: 15rem;
  }
`;
export const Grid = styled.div`
  display: grid;
  grid-template-columns: ${(p) => p.col ?? "repeat(2, 1fr)"};
  grid-template-rows: ${(p) => p.row ?? "repeat(2, 1fr)"};
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 100%;
  width: 100%;
`;

export const GridArea = styled.div`
  grid-area: ${(p) => p.area};
`;

export const Espacio = styled.div`
  height: ${(p) => p.height ?? 0};
`;

export const Iconos = styled.div`
  height: ${(p) => p.y ?? "5rem"};
  width: ${(p) => p.x ?? "5rem"};
  background-image: url(${(p) => p.img ?? ""});
  background-repeat: no-repeat;
  background-position: center;
`;

export const Footer = styled.div`
  height: 26rem;
  background-color: #eff4ec;
`;

export const imgLogoProvex = "logo_provex_borrador.png";

export const useCheckMobileScreen = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return width <= 768;
};

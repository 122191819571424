import { AcordionHome as VersionNormal } from "./AcordionHome.jsx";
import { CarruselHome as VersionMovil } from "./CarruselHome.jsx";

// ODAC ORTOPEDIA
export const srcLogoOrtopedia = "logosUnidades/LOGO_ODAC_2.png";
export const srcLogoOrtopediaSolo = "logosUnidades/LOGO_ODAC_solo.png";
export const srcAcordionHomeOrtopedia = "unidadOrtopedia.jpg";
export const colorOrtopedia = "#ff8000";
export const colorCarruselOrtopedia = "#ffeaca";

// X.PRO SANITIZANTES
export const srcLogoSanitizantes = "logosUnidades/LOGO_PRODUCTO_XPRO.png";
export const srcAcordionHomeSanitizantes = "sanitizantesHome.png";
export const srcAcordionHomeSanitizantesMovil = "sanitizantesHomeMovil.png";
export const colorSanitizantes = "#008000";
export const colorCarruselSanitizantes = "#ccffe6";

// OLIM HIGIENE
export const srcLogoHigiene = "logosUnidades/LOGO_OLIMM.png";
export const srcAcordionHomeHigiene = "higieneHome.jpg";
export const srcAcordionHomeHigienemovil = "higieneHomemovil.jpg";
export const colorHigiene = "#0047b9";
export const colorCarruselHigiene = "#e5effd";
export const colorCarruselTextoHigiene = "#0071dd";

// DARSOFT
export const srcLogoDarsoft = "darsoft_pagina_gris.png";
export const srcHomeDarsoft = "darsoftHome.jpg";
export const srcLogoDarsoftSolInfo = "darsoft_pagina_gris_soluciones.png";
export const srcLogoDarsoftSolInfoMovil =
  "darsoft_pagina_gris_soluciones_movil.png";
export const srcLogoDarsoftSolInfoFondoMovil =
  "darsoft_pagina_gris_fondo_movil.png";
export const srcLogoDarsoftSolInfoLogoMovil =
  "dasoft_logo_1-removebg-preview.png";
export const srcAcordionHomeDarsoft = "";
export const colorDarsoft = "#ff8000";
export const colorCarruselDarsoft = "#ffeaca";

// PURETI
export const srcLogoPureti = "Pureti-convierte-ciudades-en-bosques.png";
export const srcAcordionHomePureti = "PURETI.jpg";
export const srcLogoSinFondoPureti = "PURETI_logo_sinfondo.png";
export const urlPureti = "https://pureti.com.ar/";
export const UnidadesNegocioHome = () => (
  <>
    <VersionMovil />
    <VersionNormal />
  </>
);

import Pagination from 'react-bootstrap/Pagination'

const Pages = ({totalItems, itemsPerPage, active, onClick}) => {
    const pageNumbers = []
    const activePage = () => {
        for(let index = 0; index < pageNumbers.length; index++)
        {
            if(pageNumbers[index].props.active)
                return pageNumbers[index].key
        }
    }

    const nextPage = () => {
        const page = activePage()
        if(parseInt(page) === pageNumbers.length)
            return pageNumbers.length
        else
            return parseInt(page) + 1
    }

    const previousPage = () =>{
        const page = activePage()
        if(page === '1')
            return 1
        else
            return page - 1
    }

    for(let index = 1; index <= Math.ceil(totalItems / itemsPerPage); index++)
    {
        pageNumbers.push(
            <Pagination.Item key={index} active={index === active}  activeLabel={''} onClick={() => onClick(index)}>
                {index}
            </Pagination.Item>
        )
    }

    return(
        <>
            <Pagination>
                <Pagination.First onClick={() => onClick(1)} />
                <Pagination.Prev onClick={() => onClick(previousPage())}/>
                {pageNumbers}
                <Pagination.Next onClick={() => onClick(nextPage())}/>
                <Pagination.Last onClick={() => onClick(pageNumbers.length)} />
            </Pagination>
        </>
    )
}
export default Pages
import ExternalLinks from "./ExternalLinks";
import styled from "styled-components";
import { imgLogoProvex } from "./util";
import { APIURL } from "../services/productos.services";

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  /* width: 100%; */

  /* height: 2em; */
  /* margin-top: 10%; */
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const getServicios = (empresa) => {
  switch (empresa) {
    case "ORTOPEDIA":
      return (
        <ul>
          <li>
            Asesoramiento y evaluación presencial para planificación y
            desarrollo de proyectos integrales de dispensación
          </li>
          <li>Presupuestos personalizados y capacitaciones sin cargo</li>
          <li>Instalación y mantenimiento de dispensers</li>
          <li>Reposiciones y entregas inmediatas a domicilio</li>
          <li>Personalización de productos</li>
        </ul>
      );
    case "HIGIENE INSTITUCIONAL":
      return (
        <ul>
          <li>
            Asesoramiento y evaluación presencial para planificación y
            desarrollo de proyectos integrales de dispensación
          </li>
          <li>Presupuestos personalizados y capacitaciones sin cargo</li>
          <li>Instalación y mantenimiento de dispensers</li>
          <li>Reposiciones y entregas inmediatas a domicilio</li>
          <li>Personalización de productos</li>
        </ul>
      );
    case "SANITIZANTES":
      return (
        <ul>
          <li>
            Asesoramiento y evaluación presencial para planificación y
            desarrollo de proyectos integrales de dispensación
          </li>
          <li>Presupuestos personalizados y capacitaciones sin cargo</li>
          <li>Instalación y mantenimiento de dispensers</li>
          <li>Reposiciones y entregas inmediatas a domicilio</li>
          <li>Personalización de productos</li>
        </ul>
      );
    default:
      return null;
  }
};

const getConvenios = (empresa) => {
  switch (empresa) {
    case "ORTOPEDIA":
      return (
        <ul>
          <li>
            Dispensers en comodato con contratos por 24 meses y revisión
            trimestral de precios preacordados
          </li>
          <li>
            Banco de stock con reposición y facturación mensual y revisión
            trimestral de precios preacordados
          </li>
          <li>
            Ordenes de compra abiertas con acuerdos de precios semestrales o
            trimestrales
          </li>
        </ul>
      );
    case "HIGIENE INSTITUCIONAL":
      return (
        <ul>
          <li>
            Dispensers en comodato con contratos por 24 meses y revisión
            trimestral de precios preacordados
          </li>
          <li>
            Banco de stock con reposición y facturación mensual y revisión
            trimestral de precios preacordados
          </li>
          <li>
            Ordenes de compra abiertas con acuerdos de precios semestrales o
            trimestrales
          </li>
        </ul>
      );
    case "SANITIZANTES":
      return (
        <ul>
          <li>
            Dispensers en comodato con contratos por 24 meses y revisión
            trimestral de precios preacordados
          </li>
          <li>
            Banco de stock con reposición y facturación mensual y revisión
            trimestral de precios preacordados
          </li>
          <li>
            Ordenes de compra abiertas con acuerdos de precios semestrales o
            trimestrales
          </li>
        </ul>
      );
    default:
      return null;
  }
};

export const getImages = (empresa) => {
  switch (empresa) {
    case "ORTOPEDIA":
      return (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div
            style={{
              width: "13rem",
              height: "13rem",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            {
              <img
                alt={"img1"}
                src={APIURL + "/fotos/84f3a8d2-2cea-4fa0-81ad-002d19595e37.png"}
                style={{ height: "100%", width: "100%" }}
              />
            }
          </div>
          <div
            style={{
              width: "13rem",
              height: "13rem",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            {
              <img
                alt={"img1"}
                src={APIURL + "/fotos/ead003b6-7ff9-4aa0-9d49-0ca5c5dd1e7a.png"}
                style={{ height: "100%", width: "100%" }}
              />
            }
          </div>
          <div
            style={{
              width: "13rem",
              height: "13rem",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            {
              <img
                alt={"img1"}
                src={APIURL + "/fotos/4bda0cd7-0c68-4ca8-8002-da522932073c.png"}
                style={{ height: "100%", width: "100%" }}
              />
            }
          </div>
        </div>
      );
    case "HIGIENE INSTITUCIONAL":
      return (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div
            style={{
              width: "13rem",
              height: "13rem",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            {
              <img
                alt={"img1"}
                src={APIURL + "/fotos/84f3a8d2-2cea-4fa0-81ad-002d19595e37.png"}
                style={{ height: "100%", width: "100%" }}
              />
            }
          </div>
          <div
            style={{
              width: "13rem",
              height: "13rem",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            {
              <img
                alt={"img1"}
                src={APIURL + "/fotos/ead003b6-7ff9-4aa0-9d49-0ca5c5dd1e7a.png"}
                style={{ height: "100%", width: "100%" }}
              />
            }
          </div>
          <div
            style={{
              width: "13rem",
              height: "13rem",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            {
              <img
                alt={"img1"}
                src={APIURL + "/fotos/4bda0cd7-0c68-4ca8-8002-da522932073c.png"}
                style={{ height: "100%", width: "100%" }}
              />
            }
          </div>
        </div>
      );
    case "SANITIZANTES":
      return (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div
            style={{
              width: "13rem",
              height: "13rem",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            {
              <img
                alt={"img1"}
                src={APIURL + "/fotos/84f3a8d2-2cea-4fa0-81ad-002d19595e37.png"}
                style={{ height: "100%", width: "100%" }}
              />
            }
          </div>
          <div
            style={{
              width: "13rem",
              height: "13rem",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            {
              <img
                alt={"img1"}
                src={APIURL + "/fotos/ead003b6-7ff9-4aa0-9d49-0ca5c5dd1e7a.png"}
                style={{ height: "100%", width: "100%" }}
              />
            }
          </div>
          <div
            style={{
              width: "13rem",
              height: "13rem",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            {
              <img
                alt={"img1"}
                src={APIURL + "/fotos/4bda0cd7-0c68-4ca8-8002-da522932073c.png"}
                style={{ height: "100%", width: "100%" }}
              />
            }
          </div>
        </div>
      );
    default:
      return null;
  }
};

const getTitle = (servPersonalizados) => {
  return (
    <>
      {servPersonalizados ? (
        <h2>SERVICIOS PERSONALIZADOS</h2>
      ) : (
        <h2>CONVENIOS ESPECIALES</h2>
      )}
    </>
  );
};

const Institucional = ({ empresa, servPersonalizados, color }) => {
  return (
    <>
      <Container className="pb-1">
        <div className="px-5">
          {getTitle(servPersonalizados)}
          {servPersonalizados ? getServicios(empresa) : getConvenios(empresa)}
        </div>
        {servPersonalizados ? (
          <div style={{ textAlign: "center" }}>
            {/* <h4 style={{ marginBottom: "rem" }}>
              Productos personalizados de algunos de nuestros clientes
            </h4>
            {getImages(empresa)} */}
          </div>
        ) : null}
      </Container>
      <Footer>
        <img
          style={{ width: "8rem", padding: "1rem 0", alignSelf: "center" }}
          src={imgLogoProvex}
          alt="provex"
        />
        <ExternalLinks />
      </Footer>
    </>
  );
};

export default Institucional;

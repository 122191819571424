import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import {
  CenterDiv,
  imgLogoProvex,
  LogoCentrado,
  useCheckMobileScreen,
} from "./util";
import Dialog from "./Dialog";
import Institucional from "./Institucional";
import { Col, Container, Image, Row } from "react-bootstrap";

const StyledButton = styled.button`
  background: ${(p) => p.backgroundColor + "BF"};
  border-radius: 0.2rem;
  height: 3rem;
  width: 8rem;
  margin: 0.5rem;
  color: white;
  border: none;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

// const StyledGrid = styled.div`
//   display: grid;
//   grid-template-columns: repeat(11, 1fr);
//   grid-template-rows: 0.5fr 1fr 0.5fr;
//   grid-column-gap: 0px;
//   grid-row-gap: 0px;
// `;

const Navbar = ({
  title,
  midTitle,
  subtitle,
  color,
  logoNavbar = <Image src={imgLogoProvex} alt="provex" />,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [servPersonalizados, setServPersonalizados] = useState(false);
  const isMobile = useCheckMobileScreen();

  const onClick = (servPersonalizados) => {
    setShowModal(!showModal);
    setServPersonalizados(servPersonalizados);
  };

  const onClose = () => {
    setShowModal(!showModal);
  };
  return (
    <>
      <nav className="navbar navbar-light bg-light">
        <Container>
          <Row style={{ alignItems: "center" }}>
            <Col lg={2} xs={6}>
              <LogoCentrado
                center={!isMobile}
                style={{
                  transform: isMobile ? "scale(0.5)" : "scale(0.7)",
                  transformOrigin: "left",
                }}
              >
                <NavLink to="/">{logoNavbar}</NavLink>
              </LogoCentrado>
            </Col>
            <Col xs={6} style={{ alignSelf: "center" }}>
              <CenterDiv>
                {isMobile ? (
                  <h5
                    style={{
                      color: `${color}`,
                      textAlign: "-webkit-center",
                    }}
                  >
                    {midTitle}
                  </h5>
                ) : (
                  <h2
                    style={{
                      color: `${color}`,
                      textAlign: "-webkit-center",
                    }}
                  >
                    {midTitle}
                  </h2>
                )}
              </CenterDiv>
            </Col>
            <Col lg={4}>
              {title && (
                <Container>
                  <Row style={{ textAlign: "end" }}>
                    <Col>
                      <div>
                        <StyledButton
                          backgroundColor={`${color}`}
                          onClick={() => onClick(true)}
                        >
                          Servicios
                        </StyledButton>

                        <StyledButton
                          backgroundColor={`${color}`}
                          onClick={() => onClick(false)}
                        >
                          Convenios
                        </StyledButton>
                      </div>
                    </Col>
                  </Row>
                </Container>
              )}
            </Col>
          </Row>
        </Container>
      </nav>
      <Dialog
        color={color}
        show={showModal}
        body={
          <Institucional
            empresa={title}
            servPersonalizados={servPersonalizados}
          />
        }
        title={title}
        subtitle={subtitle}
        onClose={onClose}
      />
    </>
  );
};
export default Navbar;

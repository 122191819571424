import React, { useState } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { APIURL } from "../services/productos.services";
import { imgLogoProvex } from "./util";

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 2em;
`;

const Container = styled.div`
  display: flex;
  justify-content: "space-between";
`;

const ItemDetail = ({ item }) => {
  const [showDescripcion, setShowDescripcion] = useState(false);

  return item !== undefined ? (
    <>
      <Container>
        <div
          style={{
            width: "50%",
            margin: "0 1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            alt={item.nombre}
            src={`${APIURL}/${item.url_imagen}`}
            style={{ maxWidth: "90%", objectFit: "contain" }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "producto_no_disponible.png";
            }}
          />
        </div>

        <div style={{ width: "50%", margin: "0 2% 0 2%" }}>
          <h4>{item.nombre}</h4>
          <Button
            className="d-block d-sm-none"
            variant="link"
            onClick={(_) => setShowDescripcion(!showDescripcion)}
          >
            Ver {showDescripcion ? "menos" : "mas"}
          </Button>
          <div className="d-none d-sm-block">{item.descripcion}</div>
          {showDescripcion && item.descripcion}
        </div>
      </Container>
    </>
  ) : null;
};

export default ItemDetail;

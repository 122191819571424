import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import {
  srcAcordionHomeHigiene,
  srcAcordionHomeOrtopedia,
  srcAcordionHomeSanitizantes,
} from "./UnidadesNegocioHome";

import styled from "styled-components";
import { imgLogoProvex } from "./util";

const StyledModal = styled(Modal)`
  .modal-content {
    border: 1px;
    border-radius: 0.4rem;
  }
`;
const BlurBackground = styled.div`
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  width: 100%;
`;
const Dialog = ({ show, title, subtitle, body, onClose, color = "green" }) => {
  const imageHeader = (title) => {
    switch (title) {
      case "SANITIZANTES":
        return srcAcordionHomeSanitizantes;
      case "ORTOPEDIA":
        return srcAcordionHomeOrtopedia;
      case "HIGIENE INSTITUCIONAL":
        return srcAcordionHomeHigiene;
      default:
        return "";
    }
  };
  return (
    <div>
      {body !== undefined ? (
        <StyledModal
          style={{ padding: "2rem" }}
          show={show}
          onHide={onClose}
          centered
          size="lg"
        >
          <Modal.Header
            style={{
              border: "none",
              borderRadius: "0.4rem",
              padding: "0px",
              paddingBottom: "2em",
            }}
          >
            <Modal.Title
              style={{
                height: "4em",
                width: "100%",
                borderRadius: "0.4rem",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  borderRadius: "0.4rem 0.4rem 0 0",
                  height: "100%",
                  width: "100%",
                  backgroundImage: `url(${imageHeader(title)})`,
                  backgroundSize: "cover",
                }}
              >
                <BlurBackground
                  style={{
                    display: "flex",
                    height: "100%",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    flexDirection: "column",
                    color: "white",
                    borderRadius: "0.4rem 0.4rem 0 0",
                    // backgroundColor: "rgb(255,255,255,0.3)",
                  }}
                >
                  <h2
                    style={{
                      color,
                      borderRadius: "0.4rem",
                      padding: "0.4rem 1rem ",
                      // background:
                      //   "radial-gradient(circle, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.6) 100%)",
                    }}
                  >
                    {title}
                  </h2>
                  <h3>{subtitle}</h3>
                </BlurBackground>
              </div>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>{body}</Modal.Body>
          <Modal.Footer>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <img
                style={{ height: "2rem" }}
                src={imgLogoProvex}
                alt="provex"
              />
              <Button
                onClick={onClose}
                style={{ backgroundColor: color, borderColor: color }}
              >
                Volver
              </Button>
            </div>
          </Modal.Footer>
        </StyledModal>
      ) : null}
    </div>
  );
};
export default Dialog;

import React from "react";
import Home from "./pages/home";
import Ortopedia from "./pages/ortopedia";
import Sanitizantes from "./pages/sanitizantes";
import Higiene from "./pages/higiene";
import Darsoft from "./pages/darsoft";

import { Switch, Route } from "react-router-dom";
import { HashRouter } from "react-router-dom/cjs/react-router-dom";
function App() {
  return (
    <div className="App">
      <HashRouter>
        <Switch>
          <Route path="/ortopedia" component={Ortopedia} />
          <Route path="/sanitizantes" component={Sanitizantes} />
          <Route path="/higiene" component={Higiene} />
          <Route path="/darsoft" component={Darsoft} />
          <Route path="/" component={Home} />
        </Switch>
      </HashRouter>
    </div>
  );
}
export default App;

import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

const CuadroVirtudesHome = styled.div`
  background: #d7efc4;
  border-radius: 5em 0;
  padding: 1rem;
`;

const CardCuadradoNosotros = styled.div`
  text-align: left;
  padding: 1rem 0;
`;

const IconoCuadradoNosotros = styled.img`
  height: 5rem;
  width: 5rem;
  @media screen and (max-width: 767px) {
    margin-top: 1rem;
  }
`;

const textoServDif = `Trabajamos bajo una filosofía de eficiencia que le ha
permitido crecer y captar nuevos e interesantes clientes,
fidelizando igualmente a los históricos, con una estrategia
sustentada en el diferencial que representa para la empresa su
amplio catálogo de productos y servicios. Todas las unidades
cuentan con personal altamente capacitado para brindar
asesoramiento y seguimiento continuo. Es de suma importancia
para Provex S.R.L una satisfactoria atención al cliente.`;

const textoExperiencia = `Vasta experiencia en el mercado, elegidos día a día por grandes
Instituciones de salud, obras sociales, prepagas y muchos otros
rubros. Reconocidos por nuestra labor personalizada, atendiendo
cada detalle con responsabilidad y eficiencia.`;

const textoLogEficaz = `Envíos a todo el país, siempre priorizando el estado final
del/los producto/s, velando por su integridad. La puntualidad en
los plazos pactados se ve reflejada en la satisfacción de los
clientes. Confianza y seriedad nos identifican en todo momento.`;

const textoCompromiso = `La línea X.PRO, desarrollada y producida por nuestra empresa,
cuenta con productos que preservan el medio ambiente. Muchos de
nuestros productos son envasados en Bag In Box, un moderno y
eficiente formato de pouch con válvula completamente
descartables, que evitan la colonización de bacterias en el pico
dosificador, reduciendo a su vez los desechos no reciclables
para una mayor bioseguridad y cuidado de la ecología.`;

export const VirtudesHome = () => {
  const [showServDif, setShowServDif] = useState(false);
  const [showExperiencia, setShowExperiencia] = useState(false);
  const [showLogEficaz, setShowLogEficaz] = useState(false);
  const [showCompromiso, setShowSCompromiso] = useState(false);
  return (
    <CuadroVirtudesHome>
      <Container>
        <Row>
          <Col lg={1} xs={4}>
            <IconoCuadradoNosotros
              alt={"Servicio diferencial"}
              src={"./servicio_diferencial.png"}
            />
          </Col>
          <Col lg={5} xs={8}>
            <CardCuadradoNosotros>
              <h4>
                <b>Servicio diferencial</b>
                <Button
                  className="d-block d-sm-none"
                  variant="link"
                  onClick={(_) => setShowServDif(!showServDif)}
                >
                  Ver {showServDif ? "menos" : "mas"}
                </Button>
              </h4>
              <div className="d-none d-sm-block">{textoServDif}</div>
            </CardCuadradoNosotros>
          </Col>
          {showServDif && textoServDif}
          <Col lg={1} xs={4}>
            <IconoCuadradoNosotros
              alt={"Experiencia"}
              src={"./experiencia.png"}
            />
          </Col>
          <Col lg={5} xs={8}>
            <CardCuadradoNosotros>
              <h4>
                <b>Experiencia</b>
                <Button
                  className="d-block d-sm-none"
                  variant="link"
                  onClick={(_) => setShowExperiencia(!showExperiencia)}
                >
                  Ver {showExperiencia ? "menos" : "mas"}
                </Button>
              </h4>
              <div className="d-none d-sm-block">{textoExperiencia}</div>
            </CardCuadradoNosotros>
          </Col>
          {showExperiencia && textoExperiencia}
        </Row>
        <Row>
          <Col lg={1} xs={4}>
            <IconoCuadradoNosotros
              alt={"Logística eficaz"}
              src={"./logistica_eficaz.png"}
            />
          </Col>
          <Col lg={5} xs={8}>
            <CardCuadradoNosotros>
              <h4>
                <b>Logística eficaz</b>
                <Button
                  className="d-block d-sm-none"
                  variant="link"
                  onClick={(_) => setShowLogEficaz(!showLogEficaz)}
                >
                  Ver {showLogEficaz ? "menos" : "mas"}
                </Button>
              </h4>
              <div className="d-none d-sm-block">{textoLogEficaz}</div>
            </CardCuadradoNosotros>
          </Col>
          {showLogEficaz && textoLogEficaz}
          <Col lg={1} xs={4}>
            <IconoCuadradoNosotros
              alt={"Compromiso con el medio ambiente"}
              src={"./compromiso_ambiente.png"}
            />
          </Col>
          <Col lg={5} xs={8}>
            <CardCuadradoNosotros>
              <h4>
                <b>Compromiso con el medio ambiente</b>
                <Button
                  className="d-block d-sm-none"
                  variant="link"
                  onClick={(_) => setShowSCompromiso(!showCompromiso)}
                >
                  Ver {showCompromiso ? "menos" : "mas"}
                </Button>
              </h4>
              <div className="d-none d-sm-block">{textoCompromiso}</div>
            </CardCuadradoNosotros>
          </Col>
          {showCompromiso && textoCompromiso}
        </Row>
      </Container>
    </CuadroVirtudesHome>
  );
};
